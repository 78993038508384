@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  min-height: 100%;
  min-width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

.glass-navbar {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.we-create {
  color: rgba(0, 0, 0, 1);
  font-size: 40px;
  font-weight: 400;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-highlight {
  display: inline-block;
  background-color: #3FA9F5;
  border-radius: 5px;
  padding: 0 10px;
  text-align: center;
  color: white;
  font-size: 54px;
  font-weight: 700;
  line-height: 1;
}

.explore-subtext {
  color: rgba(0, 0, 0, 1);
  font-size: 22px;
  font-weight: 700;
  margin-top: 5px;
  border-bottom: 3px solid #3FA9F5;
  padding-bottom: 5px;
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.w-full {
  width: 100%;
}

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem;
  margin-left: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.p-4 {
  padding: 1rem;
}

.logo {
  transition: all 0.3s ease-in-out;
}

.logo.scaled {
  transform: scale(0.5);
  position: fixed;
  top: -45px;
  left: 500px;
}


svg {
  width: 200px;
  height: 200px;
  margin-top: 20px;
}

.blur-background {
  filter: blur(8px);
  transition: filter 0.3s ease-in-out;
}

/* Services & Solutions */

.bento-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.bento-grid-item {
  position: relative;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;

}

.bento-grid-item.large {
  grid-column: span 2;
  grid-row: span 2;
}

.bento-grid-item.medium {
  grid-column: span 2;
}

.bento-grid-item.small {
  grid-column: span 1;
}

.bento-grid-item .icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.bento-grid-item .title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.bento-grid-item .description {
  font-size: 1rem;
  color: #555;
}

.bento-grid-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bento-grid-item:hover::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 1rem;
  padding: 1px;
  background: linear-gradient(45deg, #3FA9F5, #F9A620, #E4FF1A);
  animation: gradient-animation 5s infinite;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.detailed-view {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.loader {
  width: 215px;
  height: 220px;
  background:
          linear-gradient(0.25turn, transparent, #FFF, transparent),
          linear-gradient(#DDD, #DDD),
          linear-gradient(#DDD, #DDD),
          linear-gradient(#DDD, #DDD);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: 215px 220px, 215px 130px, 100px 15px, 150px 15px;
  background-position: -215px 0, 0 0, 15px 150px, 15px 180px;
  animation: loading 1.5s infinite;
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes loading {
  to {
    background-position: 215px 0, 0 0, 15px 150px, 15px 180px;
  }
}

/* Contact Us */

.contact-us-container {
  position: relative;
  overflow: hidden;
}

.contact-us-container .bg-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.contact-us-container .contact-form-wrapper {
  position: relative;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* About Us */

.about-us-container {
  background: linear-gradient(to right, #ffffff, #FCFEFF, #ffffff);
  padding: 2rem;
}

.about-card {
  transition: transform 0.2s ease-in-out;
}

.about-icon {
  color: #3FA9F5;
}

.font-bold {
  font-weight: bold;
}

.text-black {
  color: black;
}

.text-white {
  color: white;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.leading-snug {
  line-height: 1.375;
}

.tracking-wide {
  letter-spacing: 0.025em;
}
