.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
    z-index: 9999;
    transition: all 0.5s ease;
    pointer-events: none;
}

.loader-container.loading {
    opacity: 1;
}

.loader-container.loaded {
    opacity: 0;
    pointer-events: none;
}

.loader-texts {
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
}

.loader-title {
    font-size: 24px;
    font-weight: bold;
    color: #3FA9F5;
    margin-bottom: 0; /* Adjust spacing between title and subtitle */
}

.loader-subtitle {
    font-size: 16px;
    color: #3FA9F5;
}

.loader-bar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #e0e0e0;
    overflow: hidden;
}

.loader-bar {
    height: 100%;
    background: linear-gradient(to right, #84cdfa, #3FA9F5);
    transition: width 0.3s;
}

.loader-percentage {
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 72px;
    font-weight: bold;
    color: #3FA9F5;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.loader-container.loaded .loader-texts,
.loader-container.loaded .loader-bar-container,
.loader-container.loaded .loader-percentage {
    animation: fadeOut 1s forwards;
}
