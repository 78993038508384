.contact-us-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.globe-canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.contact-form-container {
    position: relative;
    background: rgba(256, 256, 256, 0.5);
    padding: 2rem;
    border-radius: 1rem;
    max-width: 500px;
    text-align: center;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);

    border: 1px solid rgba(255, 255, 255, 0.18);
    top: 0;
    z-index: 100;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.contact-form p {
    font-size: 1rem;
    margin-bottom: 2rem;
}

.contact-input {
    width: 100%;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: none;
}

.contact-button {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.5rem;
    border: none;
    background-color: #3FA9F5;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #2a8ccb;
}
